// SmallCard.js

import React from 'react';
import '../css/home.css'

const SmallCard = ({ cardText, buttonText, onClick }) => {
  return (
    <div className="smallCard">
      <p className="cardText">{cardText}</p>
      <button className="btn" onClick={onClick}>{buttonText}</button>
    </div>
  );
};

export default SmallCard;
