// useAuth.js
import { useEffect, useState } from 'react';
import CryptoJS from 'crypto-js';

const useAuth = () => {

  // Function to encrypt the password
  const encryptPassword = (password) => {
    if (!password || !secretKey) {
        // Handle missing parameters or return an error
        return null;
      }
    const encryptedPassword = CryptoJS.AES.encrypt(password, secretKey).toString();
    return encryptedPassword;
  };
  
  // Function to decrypt the password
  const decryptPassword = (encryptedPassword) => {
    if (!encryptedPassword || !secretKey) {
        // Handle missing parameters or return an error
        return null;
      }
    const decryptedBytes = CryptoJS.AES.decrypt(encryptedPassword, secretKey);
    const decryptedPassword = decryptedBytes.toString(CryptoJS.enc.Utf8);
    return decryptedPassword;
  };
  
  // Set the secret key (make sure to keep it secure)
  const secretKey = 'sumulsecretsparlour';
  

  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const isLog = decryptPassword(sessionStorage.getItem('iL'),secretKey);
    
    setIsAuthenticated(isLog === 'true');
  }, []);

  return {
    isAuthenticated,
    encryptPassword,
    decryptPassword}
};

export default useAuth;
