import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import Button from '@mui/material/Button';
import { API_URLS } from '../api'; // Adjust the path based on your project structure
import axios from "axios";
import useAuth from '../component/useAuth.js';


const Login = () => {
  const navigate = useNavigate();
  const { encryptPassword,decryptPassword } = useAuth();

  const selectuser = [
    
    { label: 'Retail User', value: '1' },
    { label: 'Vendor', value: '2' },
    
  ];
  

  const [value, setValue] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [username, setUser] = useState('');
  const [password, setPassword] = useState('');
  

  const validate = () => {
    
    let valid = true;
    if (username === "") {
      alert("Please Enter Username");
      valid = false;
    } else if (password === "") {
      alert("Please Enter Password");
      valid = false;
    } else if (value === null) {
      alert("Please Select User Type");
      valid = false;
    }
    return valid;
  };
  

  const loginProcess = async (val, url, post) => {
    try {
      // Mocked response
      const res = await axios.post(url, post)
      // console.log(res.data);
      if (res.data.success.toString() == 'true') {
        // Handle successful login
        // console.log(res.data);
        
        
        sessionStorage.setItem('uN', encryptPassword(res.data.customer.cus_name));
        sessionStorage.setItem('uT', encryptPassword(val == 1 ? 'R' : 'V'));
        sessionStorage.setItem('ba', encryptPassword(val == 1 ? res.data.customer.balance : res.data.customer.outstanding));
        sessionStorage.setItem('uI', encryptPassword(val == 1 ? res.data.customer.cus_id.toString() : res.data.customer.cusid.toString()));
        sessionStorage.setItem('uP', encryptPassword(res.data.customer.phone));
        sessionStorage.setItem('pD', encryptPassword(res.data.customer.pwd));
        sessionStorage.setItem('iL', encryptPassword('true'));
        navigate('/home');
      }
         else {
        // Handle login error
        alert('Login failed: ' + res.data.message);
      }
      setLoading(false);
    } catch (e) {
      alert(e.message);
      setLoading(false);
    }
  };

  const handleLogin = async () => {
    
    setLoading(true);
    // console.log(value.value);
    if (value.value == 1) // If user is retail of retail app
    {
     const apiUrl = API_URLS.RETAIL_LOGIN;
     const post = { id: username, pwd: password};
     loginProcess(value.value,apiUrl,post);
    }
    else // If user is vendor user of retail app
    {
      const apiUrl = API_URLS.VENDOR_LOGIN;
      const post = { username: username, pwd: password};
     loginProcess(value.value,apiUrl,post);

    }
    
    
  
  };

  return (
    <div>
    <style>{`
      body {
        background-color: white;
        margin: 0; /* Remove default margin to fill the entire viewport */
      }
    `}</style>
    <div style={styles.container}>
      <img src={require('../img/logo.png')} alt="Logo" style={styles.logo} />
      <div style={styles.card}>
        <h2 style={{ color: 'black', marginBottom: '20px' }}>Welcome Back!</h2>
        <input
          type="text"
          placeholder="Enter Username"
          value={username}
          onChange={(e) => setUser(e.target.value)}
          style={styles.input}
        />
        <input
          type="password"
          placeholder="Enter Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          style={styles.input}
        />
        <Select
          options={selectuser}
          value={value}
          onChange={(selectedOption) => setValue(selectedOption)}
          placeholder="Select User Type"
          styles={customSelectStyles} // Custom styles for react-select
        />
        {isLoading ? (
            // Display loading spinner when isLoading is true
            <div>Loading...</div>
          ) : (
            // Display the login button when isLoading is false
            <Button
              disabled={isLoading}
              onClick={() => {
                if (validate()) {
                  handleLogin();
                }
              }}
              variant="contained"
              style={styles.button}
            >
              Login
            </Button>
          )}
      </div>
    </div>
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'white',
    minHeight: '60vh',
    padding: '20px',
    // marginTop:'-30%',
  },
  card: {
    width: '90%',
    maxWidth: '400px',
    margin: 'auto',
    padding: '20px',
    borderRadius: '10px',
    boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
    backgroundColor: '#e5e5e5',
    textAlign: 'center',
  },
  logo: {
    // marginTop:'100%',
    width: '250px',
    height: '250px',
    // marginBottom: '20px',
  },
  input: {
    width: '100%',
    padding: '10px',
    marginBottom: '15px',
    borderRadius: '5px',
    border: '1px solid #ddd',
    boxSizing: 'border-box',
  },
  button: {
    width: '100%',
    padding: '12px',
    borderRadius: '5px',
    backgroundColor: '#007bff',
    color: 'white',
    cursor: 'pointer',
  },
};

const customSelectStyles = {
  control: (provided) => ({
    ...provided,
    border: '1px solid #ddd',
    borderRadius: '5px',
    marginBottom: '15px',
  }),
};

export default Login;
