import React, { useEffect, useState } from 'react';

import { API_URLS } from '../api';
import axios from 'axios';

import { useLocation } from 'react-router-dom';
import DatePickerComponent from '../component/DatePickerComponent';
import DownloadButton from '../component/DownloadButton';
import useAuth from '../component/useAuth.js';

// import '../css/bill.css';
import DataList from '../component/DataList';
import { useNavigate } from 'react-router-dom';

function PaymentList() {
  const { encryptPassword,decryptPassword } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const screen = location?.state?.data;

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [screenTitle, setTitle] = useState('');
  const [orders, setOrders] = useState([]);
  const [user_type, setUserType] = useState(decryptPassword(sessionStorage.getItem('uT')));
  const [screen_type, setScreenType] = useState(screen);

  const [isLoaded, setIsLoaded] = useState(false);
  const [downloadStatus, setDownloadStatus] = useState('idle'); // 'idle', 'downloading', 'completed'

  const convDate = (date) => {
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    const formattedDay = day < 10 ? `0${day}` : day;
    const formattedMonth = month < 10 ? `0${month}` : month;
    return `${formattedDay}-${formattedMonth}-${year}`;
  };

  const handleStartDateChange = (date) => {
    setStartDate(date);
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
  };

  const loadOrders = async () => {
    setDownloadStatus('downloading');
    setIsLoaded(true);

    try {
      const apiUrl =
        screen === 'bill'
          ? API_URLS.GET_VENDOR_ORDER
          : user_type === 'V'
            ? API_URLS.GET_VENDOR_PAYMENT
            : API_URLS.GET_RETAIL_PAYMENT;

      const sDate = convDate(startDate);
      const eDate = convDate(endDate);

      const postData = {
        cus_id: decryptPassword(sessionStorage.getItem('uI')),
        from_date: sDate,
        to_date: eDate,
      };
      
      console.log(apiUrl);
      const res = await axios.post(apiUrl, postData);

      if (res.data.success === true) 
      {
        if (user_type == 'R')
        {
          setOrders(res.data.payment);
        }
        else
        {
          if(screen == 'bill')
          {
            setOrders(res.data.orders);
          }
          else
          {
            setOrders(res.data.payment);
          }
        }

      }
      setDownloadStatus('completed');
    } catch (error) {
      console.error('Error loading orders:', error);
      setDownloadStatus('idle');
      // Display an error message to the user
    }
  };

  useEffect(() => {
    const _isLog = decryptPassword(sessionStorage.getItem('iL'));
    if (_isLog != 'true')
    {
      navigate('/');
    }
    if (user_type === 'V' && screen_type === 'pay') {
      setTitle('Payment List');
    } else if (user_type === 'R' && screen_type === 'pay') {
      setTitle('Payment List');
    } else {
      setTitle('Bill List');
    }
  }, []);

  return (
    <>
      {isLoaded === false ? (

        <div style={styles.body}>
          <div style={styles.contentContainer}>
            <h1 style={styles.heading}>{screenTitle}</h1>

            <DatePickerComponent selected={startDate} onChange={(date) => setStartDate(date)} />
            <DatePickerComponent selected={endDate} onChange={(date) => setEndDate(date)} />

            <DownloadButton disabled={downloadStatus === 'downloading'} onClick={loadOrders} downloadStatus={downloadStatus} text="Generate" />
          </div>
        </div>
      ) : (
        <>
        <div style={styles.table} className='container'>
          <h1>{screenTitle}</h1>
          <DataList orders={orders}  listType ={screen == 'bill' ? 'B' : 'P'}/>
          <button style={styles.goBackButton} onClick={() => {setIsLoaded(false); setOrders([]);}}>Click Back</button>
          </div>
        </>
      )}
    </>
  );
}
const styles = {
  table:{
marginTop:'8%',
padding:'1%',
width:'50%',
minWidth:'300px'

  },

  goBackButton: {
    backgroundColor: '#343a40',
    color: '#ffffff',
    padding: '10px 20px',
    fontSize: '16px',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    transition: 'background-color 0.3s ease',
    marginRight: '10px', // Adjust margin as needed
  },
  body: {
    margin: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    backgroundColor: '#f8f8f8',
  },
  contentContainer: {
    backgroundColor: '#fff',
    padding: '20px',
    borderRadius: '10px',
    boxShadow: '0 0 20px rgba(0, 0, 0, 0.1)',
    width: '300px',
  },
  heading: {
    color: 'orange',
    fontSize: '28px',
    marginBottom: '20px',
  },
  
  
  
  
};

export default PaymentList;
