// index.js or index.tsx
import React from 'react';
import ReactDOM from 'react-dom';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom'; // or HashRouter
import App from './App';
import Home from './screen/Home';
import BillSummary from './screen/BillSummary';
import PaymentList from './screen/PaymentList';
import ProductCardList from './screen/ProductList';
import ChangePasswordForm from './screen/ChangePasswordForm';

ReactDOM.render(
  <Router>
    <Routes>
    <Route index element={<App />} />
    <Route path='/home' element={<Home/>}/>
    <Route path='/bills' element={<BillSummary/>}/>
    <Route path='/pays' element={<PaymentList/>}/>
    <Route path='/products' element={<ProductCardList/>}/>
    <Route path='/changepass' element={<ChangePasswordForm/>}/>
    </Routes>
  </Router>,
  document.getElementById('root')
);