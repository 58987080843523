import React from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const DatePickerComponent = ({ selected, onChange }) => {
  return (
    <div style={styles.datePickerContainer}>
      <label className="label">Select Date</label>
      <DatePicker
        selected={selected}
        onChange={onChange}
        className="date-input"
        calendarClassName="date-picker-calendar"
      />
    </div>
  );
};

const styles = {
  datePickerContainer: {
    width: '100%',
    padding: '12px',
    borderRadius: '8px',
    backgroundColor: '#f8f9fa',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    marginBottom: '20px',
    display: 'flex',
    flexDirection: 'column',
  },
  dateInput: {
    width: '100%',
    padding: '10px',
    fontSize: '16px',
    border: '1px solid #ddd',
    borderRadius: '5px',
    boxSizing: 'border-box',
    cursor: 'pointer',
  },
};

export default DatePickerComponent;
