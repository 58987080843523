import React, { useEffect, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { useLocation } from 'react-router-dom';
import DownloadButton from '../component/DownloadButton.js';
import DatePickerComponent from '../component/DatePickerComponent.js';
import useAuth from '../component/useAuth.js';
import { useNavigate } from 'react-router-dom';


function BillSummary() {
  const { encryptPassword, decryptPassword } = useAuth();
  const location = useLocation();
  const screen = location?.state?.data;
  const navigate = useNavigate();
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [screen_type, setScreenType] = useState(screen);
  const [user_type, setUserType] = useState('');
  const [screenTitle, setTitle] = useState('');
  const [downloadStatus, setDownloadStatus] = useState('idle'); // 'idle', 'downloading', 'completed'

  const convDate = (date) => {
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();

    // Pad the day and month with leading zeros if needed
    const formattedDay = day < 10 ? `0${day}` : day;
    const formattedMonth = month < 10 ? `0${month}` : month;

    return `${year}-${formattedMonth}-${formattedDay}`;
  };

  const handleButtonPress = async () => {
    let fromDate = convDate(startDate); // Format start date
    let toDate = convDate(endDate); // Format end date
    let cus_id = decryptPassword(sessionStorage.getItem('uI'));

    if (new Date(startDate) > new Date(endDate)) {
      alert("Invalid Dates", "Start date cannot be greater than the end date");
      return;
    }

    try {
      let pdfUrl;

      if (user_type === 'R') {
        pdfUrl = `https://retail.chintansumulparlour.in/billsdownload1/pdf/order/${cus_id}/${fromDate}/${toDate}`;
      } else {
        if (screen_type === 'bill') {
          pdfUrl = `https://chintansumulparlour.in/download/pdf/order/${cus_id}/${fromDate}/${toDate}`;
        } else {
          pdfUrl = `https://chintansumulparlour.in/download/pdf/pay/${cus_id}/${fromDate}/${toDate}`;
        }
      }
      setDownloadStatus('downloading');

      const response = await fetch(pdfUrl);

      if (!response.ok) {
        const errorMessage = await response.text();
        console.error('Error downloading file:', response.status, errorMessage);
        alert(`Error downloading file: ${response.status} - ${errorMessage}`);
        setDownloadStatus('idle');
        return;
      }

      const blob = await response.blob();

      const url = window.URL.createObjectURL(new Blob([blob], { type: 'application/pdf' }));
      const link = document.createElement('a');
      const timestamp = new Date().toISOString().replace(/[-T:.Z]/g, '');
      const fileName = `OrderSummary_${timestamp}.pdf`;

      link.href = url;
      link.setAttribute('download', fileName);

      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);

      // Attempt to open the file
      const newWindow = window.open(url);
      if (newWindow) {
        newWindow.focus();
      } else {
        alert('Please allow popups for this website to automatically open the downloaded file.');
      }

      window.URL.revokeObjectURL(url);
      setDownloadStatus('completed');
    } catch (error) {
      console.error('Error downloading file:', error);
      alert(`Error downloading file: ${error.message}`);
      setDownloadStatus('idle');
    }
  };

  useEffect(() => {
    const _isLog = decryptPassword(sessionStorage.getItem('iL'));
    if (_isLog !== 'true') {
      navigate('/');
    }
    setUserType(decryptPassword(sessionStorage.getItem('uT')));

    if (user_type === 'V' && screen_type === 'pay') {
      setTitle('Payment List');
    } else {
      setTitle('Bill List');
    }
  }, []);

  return (
    <>
      <div style={styles.body}>
        <div style={styles.contentContainer}>
          <h1 style={styles.heading}>{screenTitle}</h1>

          <DatePickerComponent selected={startDate} onChange={(date) => setStartDate(date)} />
          <DatePickerComponent selected={endDate} onChange={(date) => setEndDate(date)} />

          <DownloadButton disabled={downloadStatus === 'downloading'} onClick={handleButtonPress} downloadStatus={downloadStatus} text="Download" />
        </div>
      </div>
    </>
  );
}
const styles = {
  body: {
    margin: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    backgroundColor: '#f8f8f8',
  },
  contentContainer: {
    backgroundColor: '#fff',
    padding: '20px',
    borderRadius: '10px',
    boxShadow: '0 0 20px rgba(0, 0, 0, 0.1)',
    width: '300px',
  },
  heading: {
    color: 'orange',
    fontSize: '28px',
    marginBottom: '20px',
  },
  datePickerContainer: {
    marginTop: '20px',
  },
  datePicker: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    marginBottom: '20px',
  },
  label: {
    fontSize: '16px',
    color: '#555',
    marginBottom: '5px',
  },
  dateInput: {
    width: '100%',
    padding: '10px',
    fontSize: '16px',
    border: '1px solid #ddd',
    borderRadius: '5px',
    boxSizing: 'border-box',
  },
  awesomeButton: {
    backgroundColor: 'orange',
    color: '#fff',
    padding: '15px 30px',
    fontSize: '18px',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    transition: 'background-color 0.3s ease',
  },
  awesomeButtonHover: {
    backgroundColor: '#2980b9',
  },
};
export default BillSummary;
