import React from 'react';
import Button from '@mui/material/Button';

const DownloadButton = ({ disabled, onClick, downloadStatus, text }) => {
  const styles = {
    button: {
      // marginTop:'1%',
      width: '100%',
      padding: '12px',
      borderRadius: '5px',
      backgroundColor: 'orange',
      color: 'white',
      cursor: 'pointer',
    },
  };

  return (
    <Button
      disabled={disabled}
      onClick={onClick}
      variant="contained"
      style={styles.button}
    >
      {downloadStatus === 'downloading' ? 'Loading...' : text}
    </Button>
  );
};

export default DownloadButton;
