import React, { useEffect, useState } from 'react';
import useAuth from '../component/useAuth.js';
import { useNavigate } from 'react-router-dom';
import { API_URLS } from '../api.js';
import axios from 'axios';

const ChangePasswordForm = () => {
  const { encryptPassword,decryptPassword } = useAuth();
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [user_type, setUserType] = useState(decryptPassword(sessionStorage.getItem('uT')));
  const [cus_id, setCusId] = useState(decryptPassword(sessionStorage.getItem('uI')));
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const navigate = useNavigate();


  const toggleShowPassword = (passwordType) => {
    switch (passwordType) {
      case 'oldPassword':
        setShowOldPassword(!showOldPassword);
        break;
      case 'newPassword':
        setShowNewPassword(!showNewPassword);
        break;
      case 'confirmPassword':
        setShowConfirmPassword(!showConfirmPassword);
        break;
      default:
        break;
    }
  };

  const handleChangePassword = async () => {
    try {
      // setLoading(true);

      
      const apiUrl = user_type == 'R' ? API_URLS.CHANGE_RETAIL_PASS : API_URLS.CHANGE_VENDOR_PASS;
      
      const post = { cusid:cus_id, pwd: newPassword};
      // const res = await axios.post(apiUrl, post,{ headers: { 'X-Access-Token': token } } );
      const res = await axios.post(apiUrl, post );
      // console.log('API Response New:', res);
      if (res.status == 201 || res.status == 200)
      {
        alert(res.data.message);
        sessionStorage.setItem('pD', encryptPassword(newPassword));
        navigate('/home');
      }
      
    } catch (error) {
      alert(error.message);
      // console.error('API Error:', error);
    } 
  };

  const validate = ()=>{
    const storedPassword = decryptPassword(sessionStorage.getItem('pD'));
    let valid = true;
    if (oldPassword == "")
    {
      alert("Please Enter old Password");
      valid = false;
    }
    else if (newPassword == "")
    {
      alert("Please Enter New Password");
      valid = false;
    }
    else if (confirmPassword == "")
    {
      alert("Please Enter Re Password");
      valid = false;
    }
    else if (newPassword  != confirmPassword)
    {
      alert("Please Check Password");
      valid = false;
    }
    else if (oldPassword  != storedPassword)
    {
      alert("Please Check Old Password");
      valid = false;
    }
    return valid;
  }


  useEffect(() => {
    const _isLog = decryptPassword(sessionStorage.getItem('iL'));
    if (_isLog != 'true')
    {
      navigate('/');
    }
  },[])
  return (
    <div style={styles.container}>
      <h1 style={styles.heading}>Change Password</h1>
      <form style={styles.form}>
        <div style={styles.formGroup}>
          <label style={styles.label} htmlFor="oldPassword">
            Old Password
          </label>
          <div style={{ position: 'relative' }}>
            <input
              type={showOldPassword ? 'text' : 'password'}
              id="oldPassword"
              value={oldPassword}
              onChange={(e) => setOldPassword(e.target.value)}
              style={styles.input}
            />
            <span
              style={styles.showPasswordIcon}
              onClick={() => toggleShowPassword('oldPassword')}
            >
              {showOldPassword ? 'Hide' : 'Show'}
            </span>
          </div>
        </div>

        <div style={styles.formGroup}>
          <label style={styles.label} htmlFor="newPassword">
            New Password
          </label>
          <div style={{ position: 'relative' }}>
            <input
              type={showNewPassword ? 'text' : 'password'}
              id="newPassword"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              style={styles.input}
            />
            <span
              style={styles.showPasswordIcon}
              onClick={() => toggleShowPassword('newPassword')}
            >
              {showNewPassword ? 'Hide' : 'Show'}
            </span>
          </div>
          
        </div>

        <div style={styles.formGroup}>
          <label style={styles.label} htmlFor="confirmPassword">
            Confirm New Password
          </label>
          <div style={{ position: 'relative' }}>
            <input
              type={showConfirmPassword ? 'text' : 'password'}
              id="confirmPassword"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              style={styles.input}
            />
            <span
              style={styles.showPasswordIcon}
              onClick={() => toggleShowPassword('confirmPassword')}
            >
              {showConfirmPassword ? 'Hide' : 'Show'}
            </span>
          </div>
          
        </div>

        <button type="button" onClick={()=>{if (validate()) {
              handleChangePassword();
            }}} style={styles.button}>
          Change Password
        </button>
      </form>
    </div>
  );
};

const styles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '20%', // Adjust the width for smaller screens
        minWidth: '300px',
        margin: '10% auto', // Centering on larger screens
        padding: '20px',
        borderRadius: '8px',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
        backgroundColor: '#fff',
        border: '1px solid #e0e0e0',
        boxSizing: 'border-box',
        
        '@media (maxWidth: 768px)': {
          width: '90%', // Adjust the width for screens up to 768px
          margin: '10% auto', // Centering on smaller screens
        },
        '@media (maxWidth: 480px)': {
          width: '95%', // Adjust the width for screens up to 480px
          margin: '20% auto', // Further centering on very small screens
        },
      },
      
      
      
      heading: {
        color: '#ff9800', // Orange heading color
        textAlign: 'center',
        marginBottom: '20px',
      },
      
      button: {
        backgroundColor: '#ff9800', // Orange button color
        color: '#fff',
        padding: '12px',
        fontSize: '18px',
        borderRadius: '4px',
        cursor: 'pointer',
        transition: 'background-color 0.3s ease',
        '&:hover': {
          backgroundColor: '#d27d00', // Darker orange on hover
        },
      },
  form: {
    display: 'flex',
    flexDirection: 'column',
  },
  formGroup: {
    marginBottom: '20px',
  },
  label: {
    color: '#333', // Dark text color
    marginBottom: '8px',
    fontWeight: 'bold',
  },
  input: {
    padding: '10px',
    fontSize: '16px',
    border: '1px solid #ddd',
    borderRadius: '4px',
    width: '100%',
    boxSizing: 'border-box',
  },
  showPasswordIcon: {
    position: 'absolute',
    top: '50%',
    right: '10px',
    transform: 'translateY(-50%)',
    cursor: 'pointer',
    color: '#333',
  }, 
};

export default ChangePasswordForm;
