import React, { useEffect, useState } from 'react';
import { API_URLS } from '../api';
import axios from 'axios';
import useAuth from '../component/useAuth';
import { useNavigate } from 'react-router-dom';

const ProductCardList = () => {
  const { encryptPassword,decryptPassword } = useAuth();
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const loadProducts = async () => {
    try {
      const apiUrl = API_URLS.GETPRODUCTS;
      const res = await axios.get(apiUrl);
      setProducts(res.data);
    } catch (error) {
      console.error('Error fetching products:', error);
      alert('An error occurred while fetching products.');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const _isLog = decryptPassword(sessionStorage.getItem('iL'));
    if (_isLog != 'true')
    {
      navigate('/');
    }
    loadProducts();
  }, []);

  if (loading) {
    return <p>Loading...</p>; // Adjust the loading indicator as needed
  }

  return (
    <div style={styles.container}>
      <h1 className='text-center mt-5' style={styles.heading}>
        Product List
      </h1>
      <div style={styles.headingLine}></div>
      <div style={styles.cardListContainer}>
        {products.map((product) => (
          <div key={product.pid} style={styles.card}>
            <div style={styles.cardContent}>
              <div style={styles.productName}>{product.pname}</div>
              <div style={styles.price}>₹{product.price}</div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

const styles = {
  container: {
    backgroundColor: '#f5f5f5', // Light gray background
    padding: '20px',
  },
  heading: {
    color: '#3949ab', // Deep blue heading color
  },
  headingLine: {
    borderBottom: '2px solid #3949ab', // Deep blue line below heading
    margin: '10px 0',
  },
  cardListContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    marginTop: '20px',
  },
  card: {
    width: '250px',
    backgroundColor: '#ffffff',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    borderRadius: '8px',
    margin: '10px',
    border: '2px solid #3949ab', // Deep blue border
    transition: 'transform 0.3s ease', // Adding a subtle scale transition
    '&:hover': {
      transform: 'scale(1.05)', // Scale up on hover
    },
  },
  cardContent: {
    padding: '15px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
  },
  productName: {
    marginBottom: '10px',
    color: '#333', // Dark text color
    fontSize: '18px',
    fontWeight: 'bold',
  },
  price: {
    color: '#e91e63', // Pink price color
    fontSize: '20px',
    fontWeight: 'bold',
  },
};

export default ProductCardList;
