import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../css/home.css';
import { API_URLS } from '../api';
import axios from "axios";
import SmallCard from '../component/HomeCard';
import useAuth from '../component/useAuth.js';


function Home() {

  
  const [refreshing, setRefreshing] = useState(false);
  // const [getData, setAllData] = useState({ data: { outstanding: 0, balance: 0, cus_name: '' } });
  const [cus_name, setCusName] = useState('');
  const [cus_id, setCusId] = useState('');
  const [cus_type, setCusType] = useState('');
  const [cus_phone, setCusPhone] = useState('');
  const [isLog, setIsLog] = useState(false);
  const [balance, setBalance] = useState('');

  const navigate = useNavigate();
  const { encryptPassword,decryptPassword } = useAuth();

  const getUserData = async () => {

    const _isLog = decryptPassword(sessionStorage.getItem('iL'));
    if (_isLog != 'true')
    {
      navigate('/');
    }
    const _name = decryptPassword(sessionStorage.getItem('uN'));
    const _id = decryptPassword(sessionStorage.getItem('uI'));
    const _type = decryptPassword(sessionStorage.getItem('uT'));
    const _phone = decryptPassword(sessionStorage.getItem('uP'));
    const _balance = decryptPassword(sessionStorage.getItem('ba'));

    setCusName(_name);
    setCusId(_id);
    setCusType(_type);
    setCusPhone(_phone);
    setIsLog(isLog);
    setBalance(_balance);
  };


  const handleRefresh = async () => {
    setRefreshing(true);
    try {
      // const cus_id = user_type === 'retail' ? getData.data.cus_id : getData.data.cusid;
      const url = cus_type === 'R' ? API_URLS.RETAIL_BAL : API_URLS.VENDOR_BAL;
      const post = { cus_id: cus_id };

      const res = await axios.post(url, post);

      if (res.data.success.toString() === 'true') {
        sessionStorage.setItem('balance',
          cus_type === 'R' ? res.data.customer.balance : res.data.customer.outstanding);
        setBalance(sessionStorage.getItem('balance'));
      }
    } catch (error) {
      console.error('Error refreshing data:', error);
    } finally {
      setRefreshing(false);
    }
  };


  useEffect(() => {
    getUserData();
  }, []);


  return (
    <>
      <div>
        <div id="appBar">
          <h1 id="appBarText" className="text-center">Chintan Sumul Parlour</h1>
        </div>
        <div id="body">
          <h2 id="welcomeText">Welcome, {cus_name}</h2>
          <div id="balanceCard" className='text-center'>
            <p id="balanceText">Balance</p>
            <p id="balanceText" className={balance < 0 ? 'text-success' : 'text-danger'}>
              ₹ {Math.abs(balance)}
            </p>
          </div>


          <div id="main">
            <div className="cardMenu text-center">

            {cus_type === 'R' && (
              <>
            <SmallCard cardText="Bills" buttonText="View" onClick={() => navigate('/bills', { state: { data: 'bill' } })} />
            <SmallCard cardText="Payment" buttonText="View" onClick={() => navigate('/pays', { state: { data: 'pay' } })} />
            <SmallCard cardText="Product List" buttonText="View" onClick={() => navigate('/products')} />
            </>
            )}
            {cus_type === 'V' && (
              <> 
              <SmallCard cardText="Bills" buttonText="View" onClick={() => navigate('/pays', { state: { data: 'bill' } })} />
              <SmallCard cardText="Payment" buttonText="View" onClick={() => navigate('/pays', { state: { data: 'pay' } })} />
              <SmallCard cardText="Bill Summary" buttonText="View" onClick={() => navigate('/bills', { state: { data: 'bill' } })} />
              <SmallCard cardText="Payment Summary" buttonText="View" onClick={() => navigate('/bills', { state: { data: 'pay' } })} />
            </>)}
              <SmallCard cardText="Password" buttonText="Change" onClick={() => navigate('/changepass')} />

            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Home