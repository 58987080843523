const API_BASE_URL = 'https://retail.chintansumulparlour.in/api/v1';
const API_BASE_URL_VENDOR = 'https://chintansumulparlour.in/api/v1';
const LOGIN_ENDPOINT = '/login';


export const API_URLS = {
  //User api
  LOGIN: `${API_BASE_URL}${LOGIN_ENDPOINT}`,
  GETUSERS: `${API_BASE_URL}${'/users'}`,
  ADDUSER: `${API_BASE_URL}${'/adduser'}`,
  USERBYID: `${API_BASE_URL}${'/userbyid'}`,
  UPDATEUSER: `${API_BASE_URL}${'/updateuser'}`,
  DELUSER: `${API_BASE_URL}${'/deluser'}`,

  //Customer Api
  GETCUSTOMERS: `${API_BASE_URL}${'/customers'}`,
  CUSTOMERBYID: `${API_BASE_URL}${'/customerbyid'}`,
  ADDCUSTOMER: `${API_BASE_URL}${'/addcustomer'}`,
  UPDATECUSTOMER: `${API_BASE_URL}${'/updatecustomer'}`,
  DELCUSTOMER: `${API_BASE_URL}${'/delcustomer'}`,

  //Product Api
  GETPRODUCTS: `${API_BASE_URL}${'/products'}`,
  PRODUCTBYID: `${API_BASE_URL}${'/productbyid'}`,
  ADDPRODUCT: `${API_BASE_URL}${'/addproduct'}`,
  UPDATEPRODUCT: `${API_BASE_URL}${'/updateproduct'}`,
  DELPRODUCT: `${API_BASE_URL}${'/delproduct'}`,

  //Payment Api
  GETPAYMENT: `${API_BASE_URL}${'/payments'}`,
  GETPARPAYMENT: `${API_BASE_URL}${'/getpaymentparlour'}`,
  GETPAROUT: `${API_BASE_URL}${'/getparlourout'}`,
  PAYMENTBYID: `${API_BASE_URL}${'/paymentbyid'}`,
  ADDPAYMENT: `${API_BASE_URL}${'/addpayment'}`,
  ADDPARPAYMENT: `${API_BASE_URL}${'/addpaymentparlour'}`,
  UPDATEPAYMENT: `${API_BASE_URL}${'/updatepayment'}`,
  UPDATEPARPAYMENT: `${API_BASE_URL}${'/updatepaymentparlour'}`,
  DELPAYMENT: `${API_BASE_URL}${'/delpayment'}`,

  //Order Api
  GETORDER: `${API_BASE_URL}${'/orders'}`,
  GETPARORDER: `${API_BASE_URL}${'/parlourorders'}`,
  ORDERBYID: `${API_BASE_URL}${'/orderbyid'}`,
  ADDORDER: `${API_BASE_URL}${'/addorder'}`,
  UPDATEORDER: `${API_BASE_URL}${'/updateorder'}`,
  DELORDER: `${API_BASE_URL}${'/delorder'}`,

  //Retial Customer Login Api
  RETAIL_LOGIN: `${API_BASE_URL}${'/customerlogin/'}`,
  CHANGE_RETAIL_PASS:`${API_BASE_URL}${'/updatecuspass/'}`,
  GET_RETAIL_PAYMENT: `${API_BASE_URL}${'/getpaymentbyclient/'}`,
  RETAIL_BAL: `${API_BASE_URL}${'/getretailbal/'}`,

  //Vendor Login Api
  VENDOR_LOGIN: `${API_BASE_URL_VENDOR}${'/customerlogin/'}`,
  CHANGE_VENDOR_PASS:`${API_BASE_URL_VENDOR}${'/updatecuspass/'}`,
  GET_VENDOR_ORDER: `${API_BASE_URL_VENDOR}${'/getorderbyclient/'}`,
  GET_VENDOR_PAYMENT: `${API_BASE_URL_VENDOR}${'/getpaymentbyclient/'}`,
  VENDOR_BAL: `${API_BASE_URL_VENDOR}${'/getvendorbal/'}`,


};
