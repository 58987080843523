import React, { useState } from 'react';
import useAuth from '../component/useAuth.js';

const DataList = ({ orders, listType }) => {
  const { encryptPassword, decryptPassword } = useAuth();
  const [isDownloading, setIsDownloading] = useState(null);

  // Calculate total amount
  const totalAmount = orders.reduce((total, order) => total + parseFloat(order.amount), 0).toFixed(2);

  const handleDownload = async (id) => {
    try {
      // Set isDownloading to true to disable the click during the download
      setIsDownloading(id);

      const uType = decryptPassword(sessionStorage.getItem('uT'));
      let pdfUrl =
        listType === 'B'
          ? `https://chintansumulparlour.in/download/o/${id}`
          : uType === 'R'
          ? `https://retail.chintansumulparlour.in/col1/${id}`
          : `https://chintansumulparlour.in/col1/${id}`;

      const response = await fetch(pdfUrl);

      if (!response.ok) {
        const errorMessage = await response.text();
        console.error('Error downloading file:', response.status, errorMessage);
        alert(`Error downloading file: ${response.status} - ${errorMessage}`);
        return;
      }

      const blob = await response.blob();

      const url = window.URL.createObjectURL(new Blob([blob], { type: 'application/pdf' }));
      const link = document.createElement('a');
      const fileName = listType === 'B' ? `Bill_${id}.pdf` : `Pay_${id}.pdf`;

      link.href = url;
      link.setAttribute('download', fileName);

      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);

      // Attempt to open the file in a new tab
      const newWindow = window.open(url);
      if (newWindow) {
        newWindow.focus();
      } else {
        alert('Please allow popups for this website to automatically open the downloaded file.');
      }

      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error downloading file:', error);
      alert(`Error downloading file: ${error.message}`);
    } finally {
      // Set isDownloading back to null after the download is complete or encounters an error
      setIsDownloading(null);
    }
  };

  return (
    <div style={styles.tableContainer}>
      {orders.length > 0 ? (
        <table style={styles.table} className="table table-striped text-center">
          <thead>
            <tr style={styles.tableHeader}>
              <th>ID</th>
              <th>Date</th>
              <th>Amount</th>
              <th>View</th>
            </tr>
          </thead>
          <tbody>
            {orders.map((order) => (
              <tr key={listType === 'B' ? order.order_id : order.entry_id} style={styles.tableRow}>
                <td>{listType === 'B' ? order.order_id : order.entry_id}</td>
                <td>{listType === 'B' ? order.order_date : order.entry_date}</td>
                <td>{order.amount}</td>
                <td>
                  <a
                    href="#"
                    onClick={() => !isDownloading && handleDownload(listType === 'B' ? order.order_id : order.entry_id)}
                  >
                    {isDownloading === (listType === 'B' ? order.order_id : order.entry_id) ? '...' : 'View'}
                  </a>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <div style={styles.noDataContainer}>
          <span style={styles.noDataText}>No data found.</span>
        </div>
      )}
      {orders.length > 0 && (
        <div style={styles.totalAmountContainer}>
          <strong style={styles.totalAmountLabel}>Total Amount:</strong>
          <span style={styles.totalAmount}>{totalAmount}</span>
        </div>
      )}
    </div>
  );
};

const styles = {
  tableContainer: {
    marginTop: '20px',
    overflowX: 'auto',
  },
  table: {
    width: '100%',
    borderCollapse: 'collapse',
  },
  tableHeader: {
    backgroundColor: '#343a40',
    color: '#ffffff',
  },
  tableRow: {
    backgroundColor: '#f8f9fa',
    borderBottom: '1px solid #dee2e6',
    transition: 'background-color 0.3s ease',
    '&:hover': {
      backgroundColor: '#e9ecef',
    },
    td: {
      padding: '15px',
      textAlign: 'center',
      borderBottom: '1px solid #ddd',
      '&:last-child': {
        borderBottom: 'none',
      },
    },
  },
  totalAmountContainer: {
    marginTop: '20px',
    textAlign: 'right',
    padding: '15px',
    borderTop: '1px solid #ddd',
    borderBottom: '1px solid #ddd',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginBottom: '20px',
  },
  totalAmountLabel: {
    marginRight: '10px',
    fontSize: '18px',
    color: '#555',
  },
  totalAmount: {
    fontSize: '20px',
    color: '#28a745', // Green color, adjust as needed
    fontWeight: 'bold',
  },
  noDataContainer: {
    marginTop: '20px',
    textAlign: 'center',
    padding: '15px',
    color: '#555',
    borderRadius: '8px',
    backgroundColor: '#f8f9fa',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    marginBottom: '20px',
  },
  noDataText: {
    fontSize: '18px',
    fontWeight: 'bold',
    color: '#dc3545', // Red color, adjust as needed
  },
};

export default DataList;
